/*
 * This file is where we map Gutenberg blocks to SolidJs components
 */
import { Component } from 'solid-js';

/* Types */
// import { FormProps } from '../components/forms/forms.d';
import { FormProps } from '../components/mautic-form/mautic-form-types';
import { HeroComponentProps } from '../components/hero/hero-types';
import { ImageComponentProps } from '../components/image-component/image-component';
import { ColumnProps } from '../grid-system/column/column.types';
import { SectionProps } from '../grid-system/section/section-types';
import { ButtonProps } from '../ui-components/button/button.types';
import { DividerComponentProps } from '../ui-components/divider/divider-types';
import { HeadingProps } from '../ui-components/heading/heading.d';
import { CustomLinkProps } from '../ui-components/link/link.d';
import { ListProps } from '../ui-components/list/list.d';
import { QuoteProps } from '../ui-components/quote/quote-types';
import { TestimonyProps } from '../ui-components/testimony/testimony-types';
import { TextProps } from '../ui-components/text/text.types';

/* Grid System */
import { Column } from '../grid-system/column/column';
import { Section } from '../grid-system/section/section';

/* UI Components */
import { Button } from '../ui-components/button/button';
import { CodeShowcase } from '../ui-components/code-showcase/code-showcase';
import { Divider } from '../ui-components/divider/divider';
import { Heading } from '../ui-components/heading/heading';
import { Link } from '../ui-components/link/link';
import { List } from '../ui-components/list/list';
import { Quote } from '../ui-components/quote/quote';
import { ClientSelector} from '../ui-components/clients/clients';
import { Testimony } from '../ui-components/testimony/testimony';
import { Text } from '../ui-components/text/text';

/* Components */
import { BlogArchive } from '../components/blog-archive/blog-archive';
import { ContactCards, ContactCardsProps } from '../components/contact-cards/contact-cards';
// import { Forms } from '../components/forms/forms';
import { MauticForm } from '../components/mautic-form/mautic-form';
import { Hero } from '../components/hero/hero';
import { ImageComponent as Image } from '../components/image-component/image-component';
import { NewsArchive } from '../components/news-archive/news-archive';
import { ProfilePhotoCollage, ProfilePhotoCollageProps } from '../components/profile-photo-collage/profile-photo-collage';
import { ProjectArchive } from '../components/project-archive/project-archive';
import { ReadMore, ReadMoreProps } from '../components/read-more/read-more';
import { LogosGrid, LogosGridProps } from '../components/logos-grid/logos-grid';
import { VideoEmbed, VideoEmbedProps } from '../components/video-embed/video-embed';
import { ClientsProps } from '../ui-components/clients/clients-types';
import { Pricing, PricingProps } from '../components/pricing/pricing';

export type ComponentMap = {
    /* Grid System */
    'next24hr/column': Component<ColumnProps>;
    'next24hr/section': Component<SectionProps>;

    /* UI Components */
    'apelsin/button': Component<ButtonProps>;
    'core/code': any;
    'apelsin/divider': Component<DividerComponentProps>;
    'apelsin/heading': Component<HeadingProps>;
    'core/navigation-link': Component<CustomLinkProps>;
    'core/list': Component<ListProps>;
    'apelsin/quote': Component<QuoteProps>;
    'apelsin/testimony': Component<TestimonyProps>;
    'apelsin/paragraph': Component<TextProps>;
    'apelsin/blog-archive': any;
    'apelsin/news-archive': any;
    'apelsin/form-selector': Component<FormProps>;
    'apelsin/video-embed': Component<VideoEmbedProps>;
    'apelsin/clients': Component<ClientsProps>;

    /* Components */
    'apelsin/contact-cards': Component<ContactCardsProps>;
    'apelsin/hero': Component<HeroComponentProps>;
    'apelsin/image': Component<ImageComponentProps>;
    'apelsin/profile-photo-collage': Component<ProfilePhotoCollageProps>;
    'apelsin/project-archive': any;
    'apelsin/read-more': Component<ReadMoreProps>;
    'apelsin/logos-grid': Component<LogosGridProps>;
    'apelsin/pricing': Component<PricingProps>;
};

/* 
 *  Please note that if you add a component that in your opinion needs to be indexed
 *  for search engine, then you also need to specify what data needs to be extracted
 *  in data-flattener.ts
 */
export const componentMap: ComponentMap = {
    /* Grid System */
    'next24hr/column': Column,
    'next24hr/section': Section,

    /* UI Components */
    'apelsin/button': Button,
    'core/code': CodeShowcase,
    'apelsin/divider': Divider,
    'apelsin/heading': Heading,
    'core/navigation-link': Link,
    'core/list': List,
    'apelsin/quote': Quote,
    'apelsin/clients': ClientSelector,
    'apelsin/testimony': Testimony,
    'apelsin/paragraph': Text,

    /* Components */
    'apelsin/blog-archive': BlogArchive,
    'apelsin/contact-cards': ContactCards,
    // 'apelsin/form-selector': Forms,
    'apelsin/form-selector': MauticForm,
    'apelsin/hero': Hero,
    'apelsin/image': Image,
    'apelsin/profile-photo-collage': ProfilePhotoCollage,
    'apelsin/project-archive': ProjectArchive,
    'apelsin/video-embed': VideoEmbed,
    'apelsin/news-archive': NewsArchive,
    'apelsin/read-more': ReadMore,
    'apelsin/logos-grid': LogosGrid,
    'apelsin/pricing': Pricing,
};
