import { createSignal, For, Show } from 'solid-js';
import { RadioFieldAttributes } from '../mautic-form-types';


export const RadioGroup = ({ fieldData }: { fieldData: RadioFieldAttributes }) => {
    const { alias, defaultValue, error, id, isRequired, label, parentBackground, properties, radioInputClasses, handleChange, variant } = fieldData;
    const options = properties?.optionlist?.list ?? null;
    const initialValue = options && options.length === 1 
        ? defaultValue ?? false
        : defaultValue ?? '';
    const [chosen, setChosen] = createSignal(initialValue);

    const stringID = String(id);

    const handleRadioChange = (value: string) => {
        const newValue = options && options.length === 1 ? !chosen() : value;
        setChosen(newValue);
        handleChange(alias, newValue);
    };

    const checkValue = (value: string) => {
        const chosenSignal = chosen();
        if (value === chosenSignal || !!chosenSignal === true) {
            return true;
        }
        return false;
    };

    return (
        <Show when={options && options.length > 0}>
            <fieldset>
                <legend
                    class='mb-xxs'
                    classList={{
                        'text-secondary-200': variant === 'dark',
                        'text-neutral-900': variant === 'outline-dark',
                        'text-white': (variant === 'light' || variant === 'outline-light')
                    }}
                >{label}</legend>
                <div class="flex flex-col gap-xxs">
                    <For each={options}>
                        {option => (
                            <label 
                                class='relative flex cursor-pointer'
                            >
                                <input 
                                    class={radioInputClasses} 
                                    classList={{
                                        'border': !checkValue(option.value), 
                                        'border bg-secondary-200 border border-blackTransparent-40': checkValue(option.value) && (variant === 'dark' || variant === 'outline-light' ),
                                        '!bg-neutral-900 border !border-secondary-200': checkValue(option.value) && (variant === 'light' || variant === 'outline-dark' ),
                                        'border !border-[#C21B0F] placeholder-shown:border-[#C21B0F] focus-visible:border-[#C21B0F] hover:border-[#C21B0F]': error,
                                        'focus-visible:border-primary-200 focus-visible:outline focus-visible:outline-primary-200': (parentBackground === 'bg-primary-100' && variant === 'light'),
                                        'focus-visible:border-primary-300 focus-visible:outline focus-visible:outline-primary-300': ((parentBackground === 'bg-primary' || parentBackground === 'bg-primary-500') && variant === 'light'),
                                        'focus-visible:border-secondary-200 focus-visible:outline focus-visible:outline-secondary-200': ((parentBackground === 'bg-secondary' || parentBackground === 'bg-secondary-600' || parentBackground === 'bg-secondary-700') && variant === 'light'),
                                        'focus-visible:border-tertiary-200 focus-visible:outline focus-visible:outline-tertiary-200': ((parentBackground === 'bg-tertiary' || parentBackground === 'bg-tertiary-700' || parentBackground === 'bg-tertiary-800') && variant === 'light'),
                                    }}
                                    type="radio" name={alias} id={stringID + option.value} value={option.value} required={isRequired} checked={checkValue(option.value)} onChange={e => handleRadioChange(e.target.value)} />
                                <span class='pl-8'>{option.label}</span>
                            </label>
                        )}
                    </For>
                </div>
            </fieldset>
        </Show>
    );
};
