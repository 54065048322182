import { FormProps, FormValue, MauticPostAction } from './mautic-form-types';
import { Switch, Match, createSignal, Show, onMount } from 'solid-js';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Input } from './input-fields/input';
import { FieldContainer } from './field-container';
import { Textarea } from './input-fields/textarea';
import { Select } from './input-fields/select';
import { Checkbox } from './input-fields/checkbox';
import { Button } from '../../ui-components/button/button';
import { ButtonColorName } from '../../style/color-classes';
import { RadioGroup } from './input-fields/radio-group';
import { createStore } from 'solid-js/store';
import { Heading } from '../../ui-components/heading/heading';
import { useNavigate } from '@solidjs/router';
import { getClasses } from './classes';
import clsx from 'clsx';

export const MauticForm = (props: FormProps) => {
    const [errors, setErrors] = createStore<{ [key: string]: string }>({});
    const [loading, setLoading] = createSignal(false);
    const [success, setSuccess] = createSignal<string | null>(null);
    const [parentBackground, setParentBackground] = createSignal<string>('');
    const navigate = useNavigate();
    const fields = props?.form?.fields;
    const variant = props?.variant ?? 'dark';

    const handleSubmit = async (e: SubmitEvent) => {
        e.preventDefault();

        setLoading(true);
        setErrors({});

        const isValid = validateForm();

        if (!isValid) {
            setLoading(false);
            return;
        }

        try {
            const formData = new FormData();

            const values = Object.fromEntries(Object.entries(formValues)
                .map(([key, value]) => [key, Array.isArray(value) ? [...value] : value])
                .filter(([key, value]) => value !== '' && value !== null && !(Array.isArray(value) && value.length === 0)));
            console.log(values);

            for (const [key, value] of Object.entries(values)) {
                const stringValue = typeof value === 'object' ? JSON.stringify(value) : value;
                formData.append(`mauticform[${key}]`, stringValue?.toString() ?? '');
            }

            formData.append('mauticform[formId]', props.form.id);
            formData.append('mauticform[return]', props.form.return);
            formData.append('mauticform[formName]', props.form.name);
            formData.append('mauticform[messenger]', props.form.messenger);

            const response = await fetch('/postform', {
                method: 'POST',
                headers: { 'x-form-id': props.form.id },
                body: formData,
            }); 

            if (!response.ok) throw new Error(`Server responded with status ${response.status}`);

            window.gtag?.('event', 'form_submit', {
                /* eslint-disable camelcase */
                event_category: 'form',
                event_label: props.form.name,                        
                form_id: props.form.id,
                /* eslint-enable camelcase */
            });

            if (props.form.postAction === MauticPostAction.Redirect && props.form.postActionProperty) {
                const redirectUrl = props.form.postActionProperty.startsWith('/')
                    ? props.form.postActionProperty
                    : new URL(props.form.postActionProperty, window.location.origin).pathname;
            
                navigate(redirectUrl);
            }
            
            setSuccess(props.form.postAction == MauticPostAction.Message ? props.form.postActionProperty : 'Formuläret skickades!');
            setFormValues(setInitialValues());

        } catch (error) {
            setErrors('_form', error instanceof Error ? error.message : 'Ett fel uppstod vid sändning av formuläret.');
        } finally {
            setLoading(false);
        }
    };

    const setInitialValues = () => {
        if (!props.form.fields) {
            return {};
        }

        const initalValues = props.form.fields.reduce((obj: FormValue, field) => {
            const key = field.alias;

            switch (field.type) {
                case 'number': 
                    obj[key] = 0;
                    break;
                case 'checkboxgrp':
                    if (field.properties?.optionlist?.list && field.properties?.optionlist?.list.length > 1) {
                        obj[key] = [];
                        break;
                    }
                    obj[key] = false;
                    break;
                case 'radiogrp':
                    if (field.properties?.optionlist?.list && field.properties?.optionlist?.list.length > 1) {
                        obj[key] = '';
                        break;
                    }
                    obj[key] = false;
                    break;
                default: 
                    obj[key] = '';
                    break;
            }

            return obj;
        }, {});

        return initalValues;
    };

    const [formValues, setFormValues] = createStore<FormValue>(setInitialValues());

    const handleChange = (key: string, value: string | string[] | boolean | number) => {
        setFormValues(prev => ({...prev, [key]: value}));
    };

    const revalidateField = (key: string, value: string | boolean | string[] | number, validationMessage = '') => {
        const field = props.form.fields.find(f => f.alias === key);

        if (field?.isRequired && (value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0))) {
            setErrors(prev => ({ ...prev, [key]: field.validationMessage || 'This field is required' }));
        } else {
            setErrors(prev => ({ ...prev, [key]: undefined }));
        }

        if (validationMessage) {
            setErrors(prev => ({ ...prev, [key]: validationMessage }));
        }
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors: { [key: string]: string } = {};

        props.form.fields.forEach((field) => {
            const key = field.alias;
            
            if (key) {
                const value = formValues[key];

                if (field.isRequired && (!value || Array.isArray(value) && value.length === 0)) {
                    newErrors[key] = field.validationMessage ?? 'This field is required';
                    isValid = false;
                }
            }
        });

        setErrors(newErrors);

        return isValid;
    };

    onMount(() => {
        const formElement = document.querySelector(`[data-form-id="${props.form.id}"]`);
        if (formElement) {
            const closestSectionClassList = formElement.closest('[data-identifier="Section"]')?.classList;

            if (closestSectionClassList) {
                const sectionBackground = Array.from(closestSectionClassList).find(val => val.startsWith('bg-'));
    
                if (sectionBackground) {
                    setParentBackground(sectionBackground);
                    return;
                }
            }
    
            const mainElement = document.querySelector('main');
            if (mainElement) {
                const firstChildClassList = mainElement.firstElementChild?.classList;
    
                if (firstChildClassList) {
                    const pageBackground = Array.from(firstChildClassList).find(val => val.startsWith('bg-'));
    
                    if (pageBackground) {
                        setParentBackground(pageBackground);
                    }
                }
            }
            
        }
    });

    const inputLabelClasses = getClasses('label', variant);
    const inputClasses = getClasses('input', variant);
    const radioInputClasses = getClasses('radio', variant);
    const checkboxInputClasses = getClasses('checkbox', variant);

    return (
        <ErrorCatcher componentName='Header'>
            <form class="w-full flex flex-col tablet:grid tablet:grid-cols-2 p-4 gap-s" onSubmit={handleSubmit} data-form-id={props.form.id}>
                {fields && fields.map((field) => {
                    if (field.alias === 'submit') {
                        return;
                    }

                    const error = Boolean(errors[field.alias]);
                    const background = parentBackground();

                    return (
                        <FieldContainer attributes={field?.containerAttributes as string}>
                            <Switch
                                fallback={
                                    <Input fieldData={{ 
                                        ...field, 
                                        error, 
                                        inputClasses, 
                                        inputLabelClasses, 
                                        parentBackground: background,
                                        value: formValues[field.alias], 
                                        variant, 
                                        handleChange, 
                                        revalidate: revalidateField,
                                    }} />
                                }>
                                <Match when={field.type === 'textarea'}>
                                    <Textarea fieldData={{ 
                                        ...field, 
                                        error, 
                                        inputClasses, 
                                        inputLabelClasses, 
                                        parentBackground: background,
                                        value: formValues[field.alias], 
                                        variant, 
                                        handleChange, 
                                        revalidate: revalidateField 
                                    }} />
                                </Match>
                                <Match when={field.type === 'radiogrp'}>
                                    <RadioGroup fieldData={{ 
                                        ...field, 
                                        error, 
                                        radioInputClasses, 
                                        parentBackground: background,
                                        value: formValues[field.alias], 
                                        variant, 
                                        handleChange, 
                                        revalidate: revalidateField 
                                    }} />
                                </Match>
                                <Match when={field.type === 'checkboxgrp'}>
                                    <Checkbox fieldData={{ 
                                        ...field, 
                                        checkboxInputClasses, 
                                        error, 
                                        parentBackground: background,
                                        value: formValues[field.alias], 
                                        variant, 
                                        handleChange, 
                                        revalidate: revalidateField 
                                    }} />
                                </Match>
                                <Match when={field.type === 'select'}>
                                    <Select fieldData={{ 
                                        ...field, 
                                        error, 
                                        inputClasses: getClasses('select', variant), 
                                        inputLabelClasses, 
                                        parentBackground: background,
                                        value: formValues[field.alias], 
                                        variant, 
                                        handleChange, 
                                        revalidate: revalidateField
                                    }} />
                                </Match>
                                <Match when={field.type === 'button' && field.alias !== 'submit'}>
                                    <label class="sr-only" for={field.id.toLocaleString()}>{field.label}</label>
                                    <Button
                                        id={field.id.toLocaleString()}
                                        color={(variant === 'dark' || variant === 'outline-light') ? ButtonColorName.Secondary : ButtonColorName.Primary }
                                        label={field.label}
                                        variant='solid'
                                        buttonSize={'large'}
                                        arrow={true}
                                        fullWidth={true}
                                    />
                                </Match>
                            </Switch>
                            <Show when={errors[field.alias]}>
                                <p 
                                    class='text-[12px] mt-1 px-2 ml-1 bg-whiteTransparent-90 text-[#C21B0F] inline rounded-[1px]'
                                >{errors[field.alias]}</p>
                            </Show>
                        </FieldContainer>
                    );
                })}

                {/* <Show when={loading()}>
                    <p 
                        class='col-span-2'
                        classList={{
                            'text-secondary-200': variant === 'dark',
                            'text-neutral-900': variant === 'outline-dark',
                            'text-white': (variant === 'light' || variant === 'outline-light')
                        }}    
                    >Loading...</p>
                </Show> */}

                {fields && fields.map(field => {
                    return (
                        <Show when={field.type === 'submit' || field.type === 'button' && field.alias === 'submit'}>
                            <div class="col-span-2 pt-s">
                                <label class="sr-only" for={field.id.toLocaleString()}>{field.label}</label>
                                <Button 
                                    id={field.id.toLocaleString()}
                                    color={
                                        variant === 'dark'
                                            ? ButtonColorName.Primary
                                            : ButtonColorName.Secondary
                                    }
                                    disabled={loading() || !!success()}
                                    label={field.label}
                                    variant='solid'
                                    buttonSize={'large'}
                                    arrow={true}
                                    fullWidth={true}
                                    type='submit'
                                />
                            </div>
                        </Show>
                    );
                })}

                <Show when={success()}>
                    <div class="flex justify-center col-span-2">
                        <Heading tag="h4" align="center" 
                            classes={clsx(
                                'col-span-2', 
                                { 'text-secondary-200': variant === 'dark' }, 
                                { 'text-neutral-900': variant === 'outline-dark' }, 
                                { 'text-white': (variant === 'light' || variant === 'outline-light') }
                            )}
                        >
                            {success()}
                        </Heading>
                    </div>
                </Show>
            </form>
        </ErrorCatcher>
    );
};

MauticForm.parseProps = (atts: any) => {
    const { form, mauticFormId, variant } = atts;

    const formProps: FormProps = {
        variant,
        form,
        mauticFormId,
    };

    return formProps;
};
