export type FieldData = {
    field: FieldType;
};

export type ContainerAttributes = {
    attributes?: string;
    containerAttributes?: string | null;
    children?: any;
};

type ListOptions = {
    label: string;
    value: string;
};

export type FieldAttributes = {
    alias: string;
    containerAttributes?: ContainerAttributes;
    defaultValue?: string;
    error: boolean;
    id: number;
    isAutofill: boolean;
    isRequired: boolean;
    label: string;
    labelAttributes?: string;
    leadField?: string;
    parentBackground: string;
    properties?: {
        list?: {
            list: ListOptions[];
        };
        optionlist?: {
            list: ListOptions[];
        };
        placeholder?: string;
    }
    showLabel: boolean;
    type: string;
    validationMessage: string | null;
    value?: string | number | boolean | string[];
    handleChange: (key: string, value: string | number | boolean | string[]) => void;
    revalidate: (key: string, value: string | boolean | string[] | number, validationMessage?: string) => void
};

export interface InputFieldAttributes extends FieldAttributes {
    inputClasses: string;
    inputLabelClasses: string;
    variant: Variant;
}

export interface RadioFieldAttributes extends FieldAttributes {
    radioInputClasses: string;
    variant: Variant;
}

export interface CheckboxFieldAttributes extends FieldAttributes {
    checkboxInputClasses: string;
    variant: Variant;
}

export type FieldType = {
    labelIdleClasses: string;
    labelFocusClasses: string;
    inputClasses: string;
    name: string;
    slug: string;
    // customStyle?: CustomStyle; 
    label: string; 
    alias?: string;
    required?: boolean;
    isRequired?: boolean;
    type?: string;
    requiredInForm?: boolean;
    // extraSettings?: ExtraSettings;
    instance?: number;
    handleInputChange: (fieldSlug: string, fieldValue: any) => void;
    // formValues: Accessor<any>;
};

export type Variant = 'light' | 'dark' | 'outline-dark' | 'outline-light';

export type FormProps = {
    variant?: Variant;
    form: MauticForm,
    // customStyle?: CustomStyle;
    mauticFormId: string;    
};

export type MauticForm = {
    name: string;
    id: string;
    return: string;
    messenger: string;
    postAction: MauticPostAction;
    postActionProperty: string;
    fields: FieldAttributes[];
};

export type FormValue = {
    [key: string]: string | number | boolean | string[];
};

export enum MauticPostAction {
    Message = 'message',
    Redirect = 'redirect',
    StayOnForm = 'return',
}