import clsx from 'clsx';
import { Variant } from './mautic-form-types';

const classes = {
    base: {
        checkbox: 'appearance-none absolute left-0 w-s h-s rounded-s hover:cursor-pointer after:absolute after:left-0 after:top-0.5 after:w-s after:h-s',
        input: 'rounded-s p-xs w-full border',
        radio: 'appearance-none absolute left-0 w-s h-s rounded-full hover:cursor-pointer',
        select: 'rounded-s p-xs w-full border',
        label: 'absolute top-xs left-xs transition-all duration-200 ease-in-out placeholder-shown:invisible'
    },
    dark: {
        checkbox: 'bg-blackTransparent-20 border-blackTransparent-20 hover:bg-blackTransparent-50 text-secondary-200 after:text-secondary-200 after:translate-x-1',
        input: 'text-white bg-blackTransparent-20 border-blackTransparent-10 placeholder:text-whiteTransparent-60 focus-visible:border-neutral-900 focus-visible:outline focus-visible:outline-[0.5px] focus-visible:outline-neutral-900',
        radio: 'bg-blackTransparent-20 border-blackTransparent-20 hover:bg-blackTransparent-50 text-secondary-200 focus-visible:outline-neutral-900',
        select: 'bg-blackTransparent-20 border-blackTransparent-10 text-white [&:has(option:checked[value=""])]:text-whiteTransparent-70 hover:border-neutral-900 hover:outline-[0.5px] hover:outline hover:outline-neutral-900 focus-visible:outline-neutral-900 focus-visible:outline',
        label: 'text-whiteTransparent-60',
    },
    'outline-dark': {
        checkbox: 'border bg-transparent border-blackTransparent-60 hover:border-blackTransparent-80 after:translate-x-0.5',
        input: 'bg-transparent placeholder-shown:border-blackTransparent-50 placeholder:text-blackTransparent-50 text-neutral-900 border-blackTransparent-40 focus-visible:border-neutral-900 focus-visible:outline focus-visible:outline-neutral-900 focus-visible:outline-[0.5px]',
        radio: 'border border-blackTransparent-60 hover:border-blackTransparent-60 focus-visible:outline-neutral-900',
        select: 'bg-transparent [&:has(option:checked[value=""])]:border-blackTransparent-60 [&:has(option:checked[value=""])]:text-blackTransparent-60 border-secondary-900 text-neutral-900 [&:not(:has(option:checked[value=""]))]:outline [&:not(:has(option:checked[value=""]))]:outline-secondary-900 [&:not(:has(option:checked[value=""]))]:outline-[0.5px] hover:border-neutral-900  focus-visible:outline-neutral-900 focus-visible:outline',
        label: 'text-blackTransparent-40',
    },
    light: {
        checkbox: 'bg-whiteTransparent-80 border-whiteTransparent-80 hover:bg-whiteTransparent-50 after:translate-x-1',
        input: 'bg-white text-neutral-900 border-white placeholder-shown:border-blackTransparent-10 placeholder:text-neutral-600 focus-visible:border-primary-200 focus-visible:outline focus-visible:outline-[0.5px] focus-visible:outline-primary-200',
        radio: 'bg-whiteTransparent-80 border-whiteTransparent-80 hover:bg-whiteTransparent-50',
        select: 'bg-white border-white text-black [&:has(option:checked[value=""])]:text-neutral-600 focus-visible:border-primary-200 focus-visible:outline focus-visible:outline-[0.5px] focus-visible:outline-primary-200',
        label: 'text-neutral-600',
    },
    'outline-light': {
        checkbox: 'border bg-transparent border-whiteTransparent-60 hover:border-whiteTransparent-80 after:translate-x-0.5',
        input: 'bg-transparent placeholder-shown:border-whiteTransparent-60 text-white border-white placeholder:text-whiteTransparent-60 focus-visible:border-white focus-visible:outline focus-visible:outline-white focus-visible:outline-[0.5px]',
        radio: 'border border-whiteTransparent-60 hover:border-whiteTransparent-60 focus-visible:outline-secondary-200',
        select: 'bg-transparent border-white text-white [&:not(:has(option:checked[value=""]))]:outline [&:not(:has(option:checked[value=""]))]:outline-white [&:not(:has(option:checked[value=""]))]:outline-[0.5px] [&:has(option:checked[value=""])]:border-whiteTransparent-40',
        label: 'text-whiteTransparent-60',
    }
};

type Type = 'checkbox' | 'input' | 'radio' | 'select' | 'label';

export const getClasses = (type: Type, variant: Variant) => {
    return clsx(classes.base[type], classes[variant][type]);
};