import { Show, For, createSignal } from 'solid-js';
import { CheckboxFieldAttributes } from '../mautic-form-types';


export const Checkbox = ({ fieldData }: { fieldData: CheckboxFieldAttributes }) => {
    const { alias, checkboxInputClasses, defaultValue, error, id, isRequired, label, parentBackground, properties, showLabel, value, variant, handleChange, revalidate } = fieldData;
    const options = properties?.optionlist?.list ?? null;
    const initialValue = options && options.length === 1 
        ? value 
            ? !!value
            : false
        : defaultValue 
            ? [defaultValue]
            : [];
    const [chosen, setChosen] = createSignal<string[] | boolean>(initialValue);

    const stringID = String(id);

    const handleCheckboxChange = (value: string) => {
        let newValue = initialValue;

        if (options && options.length === 1) {
            newValue = !chosen();
            setChosen(newValue);
        } 
        if (options && options.length > 1) {
            newValue = chosen();
            newValue = Array.isArray(newValue) 
                ? newValue.includes(value)
                    ? newValue.filter(v => v !== value)
                    : [...newValue, value] 
                : [value];
            setChosen(newValue);
        }

        handleChange(alias, newValue);
    };

    const checkValue = (value: string) => {
        const chosenSignal = chosen();
        if (Array.isArray(chosenSignal) && chosenSignal.includes(value)) {
            return true;
        }
        if (chosenSignal === true) {
            return true;
        }
        return false;
    };

    return (
        <Show when={options && options.length > 0}>
            <fieldset id={stringID}>
                <legend 
                    classList={{ 
                        'sr-only': !showLabel, 
                        'mb-xxs': showLabel,
                        'text-secondary-200': variant === 'dark',
                        'text-neutral-900': variant === 'outline-dark',
                        'text-white': (variant === 'light' || variant === 'outline-light')
                    }}
                >{label}{isRequired ? ' *' :''}</legend>
                <For each={options}>
                    {option => (
                        <label for={id + option.value} class='flex relative' classList={{
                            'mb-xxs': (options && options.length > 1) ?? false, 
                        }}>
                            <input 
                                checked={checkValue(option.value) === !!value}
                                class={checkboxInputClasses} 
                                classList={{
                                    'after:content-check': checkValue(option.value) && (variant === 'dark' || variant === 'outline-light'),
                                    'after:content-checkDark': checkValue(option.value) && (variant === 'outline-dark' || variant === 'light'),
                                    'border !border-[#C21B0F] !placeholder-shown:border-[#C21B0F] !focus-visible:border-[#C21B0F] !focus-visible:outlinie-[#C21B0F]': error,
                                    'focus-visible:border-primary-200 focus-visible:outline focus-visible:outline-primary-200':  (parentBackground === 'bg-primary-100' && variant === 'light'),
                                    'focus-visible:border-primary-300 focus-visible:outline focus-visible:outline-primary-300':  (parentBackground === 'bg-primary-500' && variant === 'light'),
                                    'focus-visible:border-secondary-200 focus-visible:outline focus-visible:outline-secondary-200':  ((parentBackground === 'bg-secondary-600' || parentBackground === 'bg-secondary-700')&& variant === 'light'),
                                    'focus-visible:border-tertiary-200 focus-visible:outline focus-visible:outline-tertiary-200':  (parentBackground === 'bg-tertiary-700' && variant === 'light'),
                                }}
                                type="checkbox" 
                                name={alias} 
                                id={id + option.value} 
                                value={option.value} 
                                onChange={e => {
                                    handleCheckboxChange(e.target.value);
                                    revalidate(alias, e.target.value);
                                }} 
                            />
                            <span class='text-paragraphS max-w-3xl pl-8'
                            >{option.label}{(isRequired && options && options.length < 2) ? ' *' :''}</span>
                        </label>
                    )}
                </For>
            </fieldset>
        </Show>
    );
};
