import { Show } from 'solid-js';

type TextProps = {
    heading: string;
    description: string | null;
    price: string;
};

export const Text = (props: TextProps) => (
    <>
        <h2 
            class='text-headerL font-paytone'
            style={{
                background: 'var(--gradient-primary-warm, linear-gradient(180deg, #FF8B47 0%, #F15A22 100%))',
                'background-clip': 'text',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent'
            }}
        >{props.heading}</h2>
        <p class='text-paragraphL font-bold'>{props.price}</p>
        <Show when={props.description}>
            <p class='text-left text-paragraphS pb-xs'>{props.description}</p>
        </Show>
    </>
);
