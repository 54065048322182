import { createSignal } from 'solid-js';
import { InputFieldAttributes } from '../mautic-form-types';

export const Input = ({ fieldData}: { fieldData: InputFieldAttributes }) => {
    const { alias, defaultValue, error, id, inputClasses, inputLabelClasses, isAutofill, isRequired, label, parentBackground, properties, type, value, variant, handleChange, revalidate } = fieldData;
    const [isFocused, setIsFocused] = createSignal(false);

    const stringID = String(id);

    const placeHolder = () => {
        let returnValue;

        returnValue = properties?.placeholder ?? label;

        if (type === 'password') {
            returnValue = defaultValue ?? '';
        }

        if (type === 'datetime') {
            returnValue = new Date().toISOString().slice(0, 16);
        }

        return `${returnValue}${isRequired ? ' *' : ''}`;
    };

    return (
        <div class='relative'>
            <label 
                class={inputLabelClasses}
                classList={{
                    'top-xxs text-paragraphXS': isFocused() || !!value,
                    invisible: !isFocused() && (!!value === false)
                }}
                for={stringID}>{isRequired ? label + ' *' : label}
            </label>
            <input 
                autocomplete={isAutofill ? label : ''}
                class={inputClasses}
                classList={{
                    'pt-s pb-xxs': isFocused() || !!value,
                    '!border-[#C21B0F] !placeholder-shown:border-[#C21B0F] !focus-visible:border-[#C21B0F] !focus-visible:outline-none': error,
                    'focus-visible:border-primary-200 focus-visible:outline focus-visible:outline-primary-200':  (parentBackground === 'bg-primary-100' && variant === 'light'),
                    'focus-visible:border-primary-300 focus-visible:outline focus-visible:outline-primary-300':  ((parentBackground === 'bg-primary' || parentBackground === 'bg-primary-500') && variant === 'light'),
                    'focus-visible:border-secondary-200 focus-visible:outline focus-visible:outline-secondary-200':  ((parentBackground === 'bg-secondary' || parentBackground === 'bg-secondary-600' || parentBackground === 'bg-secondary-700') && variant === 'light'),
                    'focus-visible:border-tertiary-200 focus-visible:outline focus-visible:outline-tertiary-200':  ((parentBackground === 'bg-tertiary' || parentBackground === 'bg-tertiary-700' || parentBackground === 'bg-tertiary-800') && variant === 'light'),
                }}
                id={stringID}
                onBlur={(e) => {
                    if (e.target.value === '') setIsFocused(false);
                    revalidate(alias, e.target.value, e.target.validationMessage);
                }}
                onFocus={() => setIsFocused(true)}
                onInput={e => handleChange(alias, e.target.value)}
                placeholder={!isFocused() ? placeHolder() : ''}
                value={typeof value === 'boolean' ? String(value) : value}
                type={type} 
            />
        </div>

    );
};