import type { Config } from 'tailwindcss';

export default {
    content: ['./src/**/*.{js,ts,jsx,tsx,css,md,mdx,html,json,scss}'],
    theme: {
        colors: {
            black: '#000000',
            white: '#FFFFFF',
            dark: '#333333',
            transparent: 'transparent',

            primary: {
                light: '#F9B69D',
                DEFAULT: '#F15A22',
                dark: '#A13C17',
                100: '#FFEBCC',
                200: '#FFD59E',
                300: '#FFAC70',
                400: '#FF8B47',
                500: '#F15A22',
                600: '#D8440E',
                700: '#A3340A',
                800: '#732407',
                900: '#4D1805',
            },

            secondary: {
                light: '#BDA3CF',
                DEFAULT: '#7C469E',
                // DEFAULT: '#49136B',
                dark: '#49136B',
                100: '#EFE8F3',
                200: '#DED1E7',
                300: '#BDA3CF',
                400: '#9D74B6',
                500: '#7C469E',
                600: '#5B1886',
                700: '#49136B',
                800: '#370E50',
                900: '#240A36',
            },

            tertiary: {
                light: '#9DB5ED',
                DEFAULT: '#1F4FBE',
                dark: '#173B8F',
                100: '#E9EEFB',
                200: '#CEDAF6',
                300: '#9DB5ED',
                400: '#6C90E4',
                500: '#3C6CDB',
                600: '#1F4FBE',
                700: '#173B8F',
                800: '#10285F',
                900: '#081430',
            },

            neutral: {
                100: '#F4F4F5',
                200: '#E4E4E7',
                300: '#C9CACF',
                400: '#AFAFB6',
                500: '#91929C',
                600: '#686973',
                700: '#575860',
                800: '#383943',
                900: '#1D1E20',
            },

            accent1: {
                DEFAULT: '#FFA61D',
                100: '#FFF5E6',
                200: '#FFE9C6',
                300: '#FFD38E',
                400: '#FFBC55',
                500: '#FFA61D',
                600: '#E28A00',
                700: '#AA6700',
                800: '#714500',
                900: '#392200',
            },

            accent2: '#A200FF',
            accent3: '#11E2FF',

            blackTransparent: {
                '03': 'rgba(0, 0, 0, 0.03)',
                '06': 'rgba(0, 0, 0, 0.06)',
                '10': 'rgba(0, 0, 0, 0.10)',
                '20': 'rgba(0, 0, 0, 0.20)',
                '30': 'rgba(0, 0, 0, 0.30)',
                '40': 'rgba(0, 0, 0, 0.40)',
                '50': 'rgba(0, 0, 0, 0.50)',
                '60': 'rgba(0, 0, 0, 0.60)',
                '70': 'rgba(0, 0, 0, 0.70)',
                '80': 'rgba(0, 0, 0, 0.80)',
                '90': 'rgba(0, 0, 0, 0.90)',
            },

            whiteTransparent: {
                '10': 'rgba(255, 255, 255, 0.10)',
                '20': 'rgba(255, 255, 255, 0.20)',
                '30': 'rgba(255, 255, 255, 0.30)',
                '40': 'rgba(255, 255, 255, 0.40)',
                '50': 'rgba(255, 255, 255, 0.50)',
                '60': 'rgba(255, 255, 255, 0.60)',
                '70': 'rgba(255, 255, 255, 0.70)',
                '80': 'rgba(255, 255, 255, 0.80)',
                '90': 'rgba(255, 255, 255, 0.90)',
            },
        },
        boxShadow: {
            tiny: '0px 1px 0px 0.5px rgba(11,10,55,0.15)',
            sm: '0px 2px 4px 0px rgba(11,10,55,0.15)',
            lg: '0px 8px 20px 0px rgba(18,16,99,0.06)',
        },
        fontSize: {
            desktopMenu: ['1.25rem', { lineHeight: '130%' }],
            desktopMenuButton: ['1rem', { lineHeight: '130%' }],

            mobileMenu: ['1.5rem', { lineHeight: '110%' }],
            mobileSubMenu: ['1.25rem', { lineHeight: '140%' }],
            tabletMenu: ['2.25rem', { lineHeight: '120%' }],
            tabletSubMenu: ['1.75rem', { lineHeight: '100%' }],

            // These variables are defined in ./style/defaults.css
            headerXXXL: ['var(--headerXXXL)', { lineHeight: '120%' }],
            headerXXL: ['var(--headerXXL)', { lineHeight: '120%' }],
            headerXL: ['var(--headerXL)', { lineHeight: '130%' }],
            headerL: ['var(--headerL)', { lineHeight: '133%' }],
            headerM: ['var(--headerM)', { lineHeight: '140%' }],
            headerS: ['var(--headerS)', { lineHeight: '120%' }],

            paragraphXXL: ['var(--paragraphXXL)', { lineHeight: '150%' }],
            paragraphXL: ['var(--paragraphXL)', { lineHeight: '150%' }],
            paragraphL: ['var(--paragraphL)', { lineHeight: '150%' }],
            paragraphM: ['var(--paragraphM)', { lineHeight: '150%' }],
            paragraphS: ['var(--paragraphS)', { lineHeight: '150%' }],
            paragraphXS: ['var(--paragraphXS)', { lineHeight: '150%' }],

            testimonyContent: ['var(--testimonyContent)', { lineHeight: '160%' }],
            testimonyAuthor: ['var(--testimonyAuthor)', { lineHeight: '130%', fontWeight: 700 }],
            testimonyAuthorDescription: ['var(--testimonyAuthorDescription)', { lineHeight: '150%' }],

            buttonL: ['1.1875rem', { lineHeight: '140%' }],
            buttonS: ['0.9375rem', { lineHeight: '140%' }],

            code: ['1rem', { lineHeight: '160%' }],
            codeMobile: ['0.875rem', { lineHeight: '160%' }],
        },
        fontFamily: {
            poppins: 'Poppins, sans-serif',
            paytone: 'Paytone, sans-serif',
            monospace: 'DM Mono, monospace',
        },
        fontWeight: {
            bold: '700',
            medium: '500',
            normal: '400',
            light: '300',
        },
        container: {
            center: true,
        },
        lineHeight: {
            none: '1',
            tiny: '0.1rem',
        },
        borderWidth: {
            DEFAULT: '1.5px', // Feel free to change the default value
            '0': '0',
            superThin: '0.5px',
            '1': '1.5px',
            '2': '2.5px',
        },
        borderRadius: {
            none: '0',
            xs: '0.25rem',
            s: '0.5rem',
            m: '1rem', // ADJUST VALUE
            l: '1.5rem',
            xl: '3rem', // ADJUST VALUE
            xxl: '4rem', // ADJUST VALUE
            full: '100%',
        },
        screens: {
            mobile: '460px', // Uncommon
            bigMobile: '620px', // Uncommon
            tablet: '768px', // Common
            bigTablet: '840px', // Uncommon
            laptop: '1024px', // Uncommon
            desktop: '1440px', // Common
            desktopXL: '1920px', // Common
        },
        extend: {
            screenValues: {
                mobileValue: 460,
                tabletValue: 768,
                bigTabletValue: 840,
                laptopValue: 1024,
                desktopValue: 1440,
                desktopXLValue: 1920,
            },
            colorValuesForSvg: {
                'secondary-600': '#5B1886',
                'tertiary-100': '#E9EEFB',
                'secondary-100': '#EFE8F3',
            },
            gridTemplateColumns: {
                headerDesktop: 'auto 1fr auto',
                main: 'repeat(12, minmax(0, 1fr))',
                '16': 'repeat(16, minmax(0, 1fr))',
            },
            animation: {
                'fade-in': 'fade-in 0.3s ease-out forwards',
                'move-right': 'move-right 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) forwards',
                'move-right-back': 'move-right-back 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) forwards',
                'fade-in-move-up': 'fade-in-move-up 0.3s ease forwards',
                'fade-in-from-right': 'fade-in-from-right 0.3s ease forwards',
                'fade-out-to-right': 'fade-out-to-right 0.3s ease forwards',
                'wait-around-and-fade-out': 'fade-out 4s ease-out forwards',
                'close-drawer': 'close-drawer 0.3s ease-in-out',
                'open-drawer': 'open-drawer 0.3s ease-in-out',
                resize: 'all 0.3s linear',
            },
            keyframes: {
                'fade-in': {
                    '0%': { opacity: '0' },
                    '100%': { opacity: '1' },
                },
                'move-right': {
                    '0%': { transform: 'translateX(0)px' },
                    '90%': { transform: 'translateX(12px)' },
                    '100%': { transform: 'translateX(10px)' },
                },
                'move-right-back': {
                    '0%': { transform: 'translateX(10px)' },
                    '90%': { transform: 'translateX(-2px)' },
                    '100%': { transform: 'translateX(0px)' },
                },
                'fade-in-move-up': {
                    '0%': { transform: 'translateY(35px)', opacity: '0' },
                    '80%': { opacity: '1' },
                    '100%': { transform: 'translateY(0)' },
                },
                'fade-in-from-right': {
                    '0%': { transform: 'translateX(100%)', opacity: '0' },
                    '80%': { opacity: '1' },
                    '100%': { transform: 'translateX(0)' },
                },
                'fade-out-to-right': {
                    '0%': { transform: 'translateX(0)', opacity: '1' },
                    '80%': { opacity: '0' },
                    '100%': { transform: 'translateX(110%)' },
                },
                'wait-around-and-fade-out': {
                    '0%': { opacity: '1' },
                    '80%': { opacity: '1' },
                    '100%': { opacity: '0' },
                },
                'close-drawer': {
                    '0%': { transform: 'translateY(100%)' },
                    '100%': { transform: 'translateY(0)' },
                },
                'open-drawer': {
                    '0%': { transform: 'translateY(-100%)' },
                    '100%': { transform: 'translateY(0)' },
                },
            },
            spacing: {
                xxs: '0.5rem',
                xs: '1rem',
                s: '1.5rem',
                m: '2rem',
                l: '3rem',
                xl: '4rem',
                xxl: '5rem',
                xxxl: '10rem',
                '4xl': '14rem',

                containerPadding: 'var(--containerPadding)',

                sectionSpacingS: 'var(--sectionSpacingS)',
                sectionSpacingM: 'var(--sectionSpacingM)',
                sectionSpacingL: 'var(--sectionSpacingL)',
                sectionSpacingXL: 'var(--sectionSpacingXL)',
            },
            maxWidth: {
                // Main content widths
                fit: '50rem', // (800px)
                wide: '60rem', // (960px)
                maximal: '90rem', // (1440px) Used for footer etc.

                // Modal widths
                modal: '45rem',
                modalMobile: '25rem',

                // Custom post type widths
                blogPost: '50rem',
                projectPost: '50rem',
                newsPost: '54rem',

                // Text
                textMaxWidth: '56rem',
                heading1MaxWidth: '80rem',

                // Special
                fullScreen: '100vw',
            },
            width: {
                speechBubble: '15rem',
                modal: '45rem',
            },
            minHeight: {
                headerDesktop: '5.5rem',
                mobileDesktop: '5.5rem', // will be probably smaller value here
            },
            height: {
                headerDesktop: '5.5rem',
                mobileDesktop: '5.5rem', // will be probably smaller value here
                menuSafe: 'calc(100svh - 2rem)',
            },
            brightness: {
                25: '.25',
            },
            dropShadow: {
                speechBubble: '12px 12px 0px #F15A22',
                subtle: '0px 16px 48px 0px rgba(0, 0, 0, 0.08), 0px 16px 24px -8px rgba(0, 0, 0, 0.00)',
            },
            content: {
                check: 'url("./check.svg")',
                checkDark: 'url("./check-dark.svg")',
                checkBullet: 'url("./check-bullet.svg")'
            },
            boxShadow: {
                speechBubble: '13px 12px 0px 0px #F15A22',
                subtle: '0px 16px 48px 0px rgba(0, 0, 0, 0.08), 0px 16px 24px -8px rgba(0, 0, 0, 0.00)',
            },
            safelist: [
                'group-hover:blur-none',
                'group-focus:blur-none',
                'overflow-hidden',
                'rounded',
                'object-cover',
                'object-contain',
                'absolute',
                'relative',
                'w-full',
                'h-full',
            ],
        },
    },
    variants: {
        extend: {},
    },
    plugins: [],
} satisfies Config;
