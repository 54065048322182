import clsx from 'clsx';
import { ContainerAttributes } from './mautic-form-types';

export const FieldContainer = (props: ContainerAttributes) => {
    return (
        <div class={clsx(
            {'tablet:col-span-2': !props?.attributes},
            {'tablet:col-span-1': props?.attributes}
        )}>
            {props?.children as any}
        </div>
    );
};
