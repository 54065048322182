import { PricingProps } from '../pricing';
import { Text } from './text';
import { ListAndButton } from './list-and-button';


export type RenderChildrenProps = {
    classList: string;
    hasLink: boolean;
    props: PricingProps;
};

export const RenderChildren = ({ classList, hasLink, props }: RenderChildrenProps) => (
    <div 
        class={'px-s py-l rounded-l w-full h-full text-center gap-s text-neutral-800 transition ease-in-out duration-500 ' + classList}
    >
        <div class='w-full flex flex-col gap-s' classList={{ 'laptop:h-64': props.alignLists && !!props.description }}>
            <Text {...props} description={props.description.length > 1 ? props.description : null} />
        </div>
        <ListAndButton buttonProps={props.button} hasLink={hasLink} filteredValues={props.values} />
    </div>
);
