import { createSignal, For, Show } from 'solid-js';
import { InputFieldAttributes } from '../mautic-form-types';


export const Select = ({ fieldData }: { fieldData: InputFieldAttributes }) => {
    const { alias, error, id, inputClasses, isRequired, label, parentBackground, properties, handleChange, value, variant, revalidate } = fieldData;
    const [isFocused, setIsFocused] = createSignal(false);
    const [hasValue, setHasValue] = createSignal(!!value);
    const options = properties?.list?.list ?? null;
    const stringID = String(id);

    const placeholder = properties?.placeholder
        ? properties.placeholder + (isRequired ? ' *' : '')
        : label + (isRequired ? ' *' : '');

    const getCurrentColor = () => {
        switch (variant) {
            case 'light':
            case 'outline-dark':
                return '%23000000';
            case 'dark':
            case 'outline-light':
            default:
                return '%23F4F4F5';

        }
    };

    const svgIcon = () => `data:image/svg+xml,%3Csvg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath opacity="${hasValue() ? '1' : '0.7'}" d="M17 1L9.3333 9L1 0.999999" stroke="${getCurrentColor()}" stroke-width="2"/%3E%3C/svg%3E%0A`;

    return (
        <Show when={options && options.length > 0}>
            <>
                <label class='sr-only' for={stringID}>{isRequired ? label + ' *' : label}</label>
                <select 
                    class={inputClasses + ' appearance-none'}
                    classList={{
                        '!border-[#C21B0F] !placeholder-shown:border-[#C21B0F] !focus-visible:border-[#C21B0F] !focus-visible:outlinie-[#C21B0F]': error,
                        'focus-visible:border-primary-200 focus-visible:outline focus-visible:outline-primary-200 hover:border-primary-200': (parentBackground === 'bg-primary-100' && variant === 'light'),
                        'focus-visible:border-primary-300 focus-visible:outline focus-visible:outline-primary-300 hover:border-primary-300': ((parentBackground === 'bg-primary' || parentBackground === 'bg-primary-500') && variant === 'light'),
                        'focus-visible:border-secondary-200 focus-visible:outline focus-visible:outline-secondary-200 hover:border-secondary-200': ((parentBackground === 'bg-secondary' || parentBackground === 'bg-secondary-600' || parentBackground === 'bg-secondary-700') && variant === 'light'),
                        'focus-visible:border-tertiary-200 focus-visible:outline focus-visible:outline-tertiary-200 hover:border-tertiary-200': ((parentBackground === 'bg-tertiary' || parentBackground === 'bg-tertiary-700' || parentBackground === 'bg-tertiary-800') && variant === 'light'),
                    }}
                    id={stringID}
                    onBlur={() => setIsFocused(false)}
                    onChange={e => {
                        handleChange(alias, e.target.value);
                        setHasValue(e.target.value !== '');
                        revalidate(alias, e.target.value);
                    }}
                    onFocus={() => setIsFocused(true)}
                    style={{
                        'background-image': `url('${svgIcon()}')`,
                        'background-repeat': 'no-repeat',
                        'background-position': 'right 1.125rem top 50%',
                        'background-size': '0.75rem auto'
                    }}
                    value={typeof value === 'boolean' ? String(value) : value}
                >
                    <option disabled={isFocused()} value="">{placeholder}</option>
                    <For each={options}>
                        {item => (
                            <option value={item.value} selected={item.value === value}>{item.label}</option>
                        )}
                    </For>
                </select>
            </>
        </Show>
    );
};
