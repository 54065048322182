import { createEffect, createSignal, lazy, onMount, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import clsx from 'clsx';
import { ErrorCatcher } from '../../tools/error-catcher';
import { Button } from '../../ui-components/button/button';
import { ButtonProps } from '../../ui-components/button/button.types';
import { Text } from '../../ui-components/text/text';
import tailwindConfig from '../../../tailwind.config';

export const Hero = (props: any) => {
    const { pageTheme, viewport, scrollValue, setScrollValue } = useContext(AppContext);



    const isMobile = () => viewport.width <= tailwindConfig.theme.extend.screenValues.tabletValue;
    const isTablet = () => viewport.width > tailwindConfig.theme.extend.screenValues.tabletValue && viewport.width <= tailwindConfig.theme.extend.screenValues.desktopValue;
    const [riveComponent, setRiveComponent] = createSignal<any>();
    const [loaded, setLoaded] = createSignal(false);
    const [hideDiv, setHideDiv] = createSignal(false);
    const [getFontSize, setFontSize] = createSignal<'s'|'m'|'l'|'xl'|'xxl'|'xxxl'>('xl');
    const [getAspectRatio, setAspectRatio] = createSignal<'1/1'|'4/3'|'3/4'|'16/9'|'5/9'|'7/16'>('16/9');
    const [bgClasses, setbgClasses] = createSignal('aspect-video relative m-0');
    if (viewport !== undefined) {
        const isMobile = () => viewport.width <= tailwindConfig.theme.extend.screenValues.tabletValue;

        const isTablet = () => viewport.width > tailwindConfig.theme.extend.screenValues.tabletValue && viewport.width <= tailwindConfig.theme.extend.screenValues.desktopValue;

        if (isMobile()) {
            setFontSize('xl');
            setbgClasses(clsx(
                // 'aspect-[7/16]',
                'h-[100svh]',
                'relative',
                'm-0',
                {'hidden': hideDiv()},
            ));
        }
        if (isTablet()) {
            setFontSize('xxl');
            setAspectRatio('5/9');
        }
        else if (!isMobile() && !isTablet()){
            setFontSize('xxxl');
            setbgClasses(clsx(
                'h-unset',
                'aspect-video',
                'relative',
                'm-0',
                {'hidden': hideDiv()},
            ));
            setAspectRatio('16/9');
        }
    }

    onMount(async () => {
        let rive = lazy(() => import('../hero/hero-rive-mobile'));
        //if (isTablet()) {
        //    rive = lazy(() => import('../hero/hero-rive-mobile'));
        //}
        if (!isMobile() && !isTablet()) {
            setFontSize('xxxl');
            rive = lazy(() => import('../hero/hero-rive'));
        }
        setRiveComponent(rive);
    });

    const handleScrollDown = (e: any) => {
        e.preventDefault();
        if (!window) {
            return;
        }

        if (isMobile()) {
            window.scrollTo(0, window.innerHeight - 20);
        } else {
            window.scrollTo(0, window.innerHeight - 100);
        }
    };

    const handleWheelEvent = (e: any) => {
        const deltaY = e.deltaY;
        const increment = deltaY > 0 ? 1 : -1;
        const newNumber = Math.min(200, Math.max(1, scrollValue() + increment));
        setScrollValue(newNumber);
    };

    createEffect(() => {
        if (riveComponent()) {
            setTimeout(() => {
                setLoaded(true);
                setAspectRatio('16/9');
            }, 50);
            setTimeout(() => {
                setHideDiv(true);
            }, 400);
        }

    });

    const buttonProps: ButtonProps = {
        label: props.learnMoreButton.label,
        arrow: props.learnMoreButton.arrow,
        onClick: handleScrollDown,
        variant: props.learnMoreButton.variant,
        buttonSize: props.learnMoreButton.buttonSize,
        url: props.learnMoreButton.url,
    };

    const get = () => {
        const wrapperClasses = clsx(
            pageTheme().colors.classes,
            'h-screen',
            'w-full',
            'absolute',
            'text-left',
            'tablet:text-center',
            'desktop:text-center',
            'left-0',
            'right-0',
            'pr-4',
            'pl-4',
            'pt-[1.5rem]',
            'tablet:mx-auto',
            'desktop:mx-auto',
            { 'opacity-0': loaded() },
            { '!hidden': hideDiv() },
            'transition-all',
            'duration-300',
            'ease-in-out',
            // 'w-screen',
            'h-full tablet:h-screen',
        );
        return wrapperClasses;
    };
    const loadedClasses = clsx(
        'absolute',
        'text-center',
        'left-0',
        'right-0',
        'max-w-xl',
        'tablet:max-w-3xl',
        'desktop:max-w-4xl',
        'pl-8',
        'pr-8',
        'tablet:mx-auto',
        'desktop:mx-auto',
        'flex',
        'flex-col',
        'justify-center',
        // 'w-screen',
        'h-full tablet:h-screen',
    );

    let bgClassesAppend = clsx(
        'relative',
        'm-0',
        {'hidden': hideDiv()},
    );
    bgClassesAppend += ' bg-secondary-700';
    const h1Classes = clsx(
        'font-paytone',
        'text-accent1-300',
        'text-headerXXL',
    );

    return (
        <ErrorCatcher componentName="Hero">
            <div class={bgClasses()} onWheel={handleWheelEvent}>
                <div class={get()} />
                <div class={loadedClasses}>
                    <h1 class={h1Classes}>
                        {props.largerHeading}
                    </h1>
                </div>
                {riveComponent()} 
            </div>
        </ErrorCatcher>
    );
};

Hero.parseProps = (atts: any) => {
    // This is the raw image object coming from gutenberg. We dont want all details from it.

    const { image, button } = atts;
    const commonAtts = {
        altText: atts.altText,
        imageText: atts.imageText,
        eyebrowHeading: atts.eyebrowHeading,
        largerHeading: atts.largerHeading,
        description: atts.description,
        bottomDividerColor: atts.bottomDividerColor,
        learnMoreButton: button[0],
        labels: atts.labels,
        secondaryButton: button[1],
    };

    if (image) {
        return {
            src: image.url,
            width: image.width,
            focus: image.focus,
            overlayType: atts.overlayType,
            ...commonAtts,
        };
    } else return commonAtts;
};


