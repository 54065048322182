import { Show } from 'solid-js';
import { ButtonColorName } from '../../style/color-classes';
import { ButtonProps } from '../../ui-components/button/button.types';
import { A  } from '@solidjs/router';
import clsx from 'clsx';
import { RenderChildren } from './children/render-children';

export type PricingProps = {
    variant: string;
    backgroundColor?: {
        name: string;
        color: string;
    }; 
    alignLists: boolean;
    heading: string;
    price: string;
    description: string;
    listItems: number;
    values: {
        boldText: string;
        normalText: string;
    }[];
    button: Partial<ButtonProps>;
};

export const Pricing = (props: PricingProps) => {
    const { values, variant, button, listItems, backgroundColor } = props;

    const safeButton: ButtonProps = {
        arrow: button.arrow || false,
        buttonSize: button.buttonSize || 'small',
        color: button.color || ButtonColorName.Primary,
        darkMode: button.darkMode || false,
        fullWidth: button.fullWidth || true,
        label: button.label || 'Läs mer',
        target: button.target || '_blank',
        url: undefined,
        variant: button.variant || 'solid',
    };

    const filteredValues = () => values.filter((value, index) => {
        return (value.boldText || value.normalText) && index < listItems;
    });

    const hasLink = () => props.button?.url || null;
    const backgroundColorClass = () => backgroundColor ? `bg-${backgroundColor?.name}` : '';

    const classList = clsx(
        { 'bg-white': variant === 'solid' },
        { 'bg-accent1-100 border border-black rounded-l': variant === 'outline' },
        { [backgroundColorClass()]: variant === 'outline' && !!backgroundColor },
        { 'hover:scale-105': !!hasLink() },
        { 'flex flex-col': !props.alignLists },
        { 'flex flex-col': props.alignLists },
        // { 'grid grid-rows-2 grid-cols-1': props.alignLists },
    );

    return (
        <Show when={props.button.url} fallback={
            <RenderChildren 
                classList={classList} 
                hasLink={!!hasLink()}
                props={{
                    ...props, 
                    button: {...safeButton},
                    values: filteredValues()
                }} 
            />
        }>
            <A href={props.button.url || ''} class='h-full' target={props.button.target} rel={props.button.rel}>{
                <RenderChildren
                    classList={classList} 
                    hasLink={!!hasLink()}
                    props={{
                        ...props, 
                        button: {...safeButton},
                        values: filteredValues()
                    }} 
                />
            }</A>
        </Show>
    );
};

Pricing.parseProps = (atts: any) => {
    const { variant, backgroundColor, alignLists, heading, price, listItems, values, button, description } = atts;
    const unwrappedButton = button ? JSON.parse(JSON.stringify(button)) : {};
    const unwrappedbackgroundColor = backgroundColor ? JSON.parse(JSON.stringify(backgroundColor)) : {};

    const commonAtss = {
        variant,
        backgroundColor: unwrappedbackgroundColor,
        alignLists,
        heading,
        price,
        description,
        listItems,
        values,
        button: unwrappedButton,
    };

    return commonAtss;
};
