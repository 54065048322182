import { For, Show } from 'solid-js';
import { ButtonProps } from '../../../ui-components/button/button.types';
import { Button } from '../../../ui-components/button/button';

type ListAndButtonProps = {
    buttonProps?: Partial<ButtonProps>;
    filteredValues: {
        boldText: string;
        normalText: string;
    }[];
    hasLink: boolean;
};

export const ListAndButton = (props: ListAndButtonProps) => {
    const { buttonProps, filteredValues, hasLink } = props;
    
    return (
        <div class='gap-s flex-1'
            classList={{
                'grid grid-rows-[auto_auto] grid-cols-1': hasLink
            }}
        >
            <ul class='text-left flex flex-col gap-xs pb-m flex-1'>
                <For each={filteredValues} fallback={<div />}>
                    {value => (
                        <li 
                            class='flex flex-row items-top before:h-6 before:content-checkBullet'
                        >
                            <span class='pl-xs text-paragraphM'>
                                <strong>{value.boldText} </strong>
                                {value.normalText}
                            </span>
                        </li>
                    )}
                </For>
            </ul>
            <Show when={hasLink} fallback={<div class='w-full h-[45px]' />}>
                <div class='w-full px-l flex items-end'>
                    <Button
                        {...buttonProps}
                        buttonSize={buttonProps?.buttonSize || 'small'}
                        label={buttonProps?.label || ''}
                    />
                </div>
            </Show>
        </div>
    );
};
